<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The net ionic reaction for a titration between chloride ion and silver nitrate,
        <chemical-latex content="AgNO3," />
        is:
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="Cl-(aq) + Ag+(aq) -> AgCl(s)" />
      </p>

      <p class="mb-2">
        What is the concentration of
        <chemical-latex content="Cl-" />
        in seawater if the titration of a
        <number-value :value="volAnalyte" unit="\text{mL}" />
        sample of seawater requires
        <number-value :value="volTitrant" unit="\text{mL}" />
        of
        <number-value :value="concTitrant" unit="\text{M}" />
        <chemical-latex content="AgNO3?" />
      </p>

      <calculation-input
        v-model="inputs.concAnalyte"
        prepend-text="$\ce{[Cl-]}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question432',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        concAnalyte: null,
      },
    };
  },
  computed: {
    volAnalyte() {
      return this.taskState.getValueBySymbol('volAnalyte').content;
    },
    volTitrant() {
      return this.taskState.getValueBySymbol('volTitrant').content;
    },
    concTitrant() {
      return this.taskState.getValueBySymbol('concTitrant').content;
    },
  },
};
</script>
